//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'

export default {
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    methods: {
        get,
        getCssClasses (index) {
            if (index === 0) {
                return 'text-left lg:text-right text-white'
            } else {
                return ''
            }
        },
        getUrl (index) {
            if (index === 0) {
                return '/for-professional'
            } else {
                return '/for-customer'
            }
        }
    }
}
